import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import CookieNotice from './cookieNotice.component.js';
import PrivacyStatement from './privacyStatement.component.js';
import TermsofUse from './termsofUse.component.js';

function Footer() {
    return (
        <div className="col-md-12">
            <nav className="topNav">
                <Navbar className="color-nav" variant="dark" fixed="bottom">
                    <Nav className="mr-auto links">
                      <PrivacyStatement/>
                      <CookieNotice/>
                      <TermsofUse/>
                    </Nav>
                    <Nav className="mr-auto">
                        <Navbar.Text>
                            ©Northcross Group 2020
                    </Navbar.Text>
                    </Nav>
                </Navbar>
            </nav>
        </div>
    );
}

export default Footer;